import React, { useState, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';
import { Button, Stack } from '@mui/material';

const ExampleTable = () => {
  const allColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'age',
        header: 'Age'
      },
      {
        accessorKey: 'email',
        header: 'Email'
      },
      {
        accessorKey: 'address',
        header: 'Address'
      },
      {
        accessorKey: 'city',
        header: 'City'
      }
    ],
    []
  );

  // Initialize visibleColumns with allColumns to have all columns visible by default
  const [visibleColumns, setVisibleColumns] = useState(allColumns);
  const [tempSelectedColumns, setTempSelectedColumns] = useState(
    allColumns.map((col) => col.header)
  ); // Temporary state for selected columns

  const data = useMemo(
    () => [
      {
        name: 'John Doe',
        age: 30,
        email: 'john.doe@example.com',
        address: 'kolkata',
        city: 'uk'
      },
      {
        name: 'John Doe',
        age: 25,
        email: 'jane.smith@example.com',
        address: 'kolkata',
        city: 'uk'
      },
      {
        name: 'John Doe',
        age: 35,
        email: 'mike.johnson@example.com',
        address: 'kolkata',
        city: 'uk'
      }
    ],
    []
  );

  const handleColumnToggle = (event) => {
    const {
      target: { value }
    } = event;

    // Check if "Select All" is being clicked
    if (value.includes('Select All')) {
      if (tempSelectedColumns.length === allColumns.length) {
        // If all columns are currently selected, deselect all
        setTempSelectedColumns([]);
      } else {
        // If not all columns are selected, select all
        setTempSelectedColumns(allColumns.map((col) => col.header));
      }
    } else {
      // Otherwise, update the temporary selected columns
      setTempSelectedColumns(value);
    }
  };

  const applyChanges = () => {
    // Update visibleColumns based on tempSelectedColumns
    if (tempSelectedColumns.length === 0) {
      setVisibleColumns([]); // Hide all if none are selected
    } else {
      setVisibleColumns(allColumns.filter((column) => tempSelectedColumns.includes(column.header)));
    }
  };

  const tableInstance = useMaterialReactTable({
    columns: visibleColumns,
    data
  });

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Columns</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={tempSelectedColumns}
          onChange={handleColumnToggle}
          input={<OutlinedInput label="Columns" />}
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem key="select-all" value="Select All">
            <Checkbox checked={tempSelectedColumns.length === allColumns.length} />
            <ListItemText primary="Select All" />
          </MenuItem>
          {allColumns.map((column) => (
            <MenuItem key={column.accessorKey} value={column.header}>
              <Checkbox checked={tempSelectedColumns.includes(column.header)} />
              <ListItemText primary={column.header} />
            </MenuItem>
          ))}
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="outlined" sx={{ color: '#000000' }}>
              Cancel
            </Button>
            <Button variant="outlined" sx={{ color: '#000000' }} onClick={applyChanges}>
              Apply
            </Button>
          </Stack>
        </Select>
      </FormControl>
      <MaterialReactTable table={tableInstance} />
    </>
  );
};

export default ExampleTable;
