import { useMemo, useEffect } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Create a custom theme

const MrtTableWithSubColumn = ({ layout, apidata, content }) => {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  useEffect(() => {
    const applyStyles = () => {
      const headers = document.querySelectorAll('.css-6yt068');
      headers.forEach((header) => {
        header.style.justifyContent = 'flex-start'; // Change to start
      });
    };

    applyStyles();

    // Reapply styles if necessary (e.g., on window resize)
    window.addEventListener('resize', applyStyles);
    return () => window.removeEventListener('resize', applyStyles);
  }, []);

  const shouldHaveColumnStyle = (cell) => {
    const bgColor = cell?.column?.columnDef?.columnHeaderColor;
    const textColor = cell?.column?.columnDef?.columnHeaderTextColor;
    //column data
    return {
      sx: {
        fontSize: '0.8rem',
        backgroundColor: bgColor ? bgColor : '#FFFFFF',
        // borderRight: '0.5px solid #E0E0E0',
        color: textColor ? textColor : '#000000',
        // padding: '0.5em'
        fontWeight: 'bold'
      }
      // Add more dynamic props here based on conditions
    };
  };

  const columns = useMemo(() => {
    return content?.tableData?.map((item, index) => ({
      header: (
        <Typography sx={{ color: item?.prnt_col_hdr_text_colr }}> {item?.prnt_fld_name}</Typography>
      ),
      columnHeaderColor: item?.prnt_col_hdr_bg_colr,
      columns: [
        {
          accessorKey: item.fld_id,
          header: content?.tableFieldData?.[index]?.fld_l,
          textColor: item?.text_color,
          bgColor: item?.bg_color,
          icon: item?.icon,
          columnHeaderColor: item?.col_hdr_bg_colr,
          columnHeaderTextColor: item?.col_hdr_text_colr,
          Cell: ({ cell }) => {
            const textColor = cell?.column?.columnDef?.textColor; //column data
            const styling = cell?.row?.original; //row data
            const icon = cell?.column?.columnDef?.icon;
            return (
              <div
                style={{
                  color: styling[textColor] ? styling[textColor] : '#000000'

                  // padding: '0.5em'
                }}
              >
                <Stack direction="row">
                  {item?.icon_pos === 'left' &&
                    (styling[icon] === 'up' ? (
                      <ArrowDropUpOutlinedIcon />
                    ) : styling[icon] === 'down' ? (
                      <ArrowDropDownIcon />
                    ) : null)}
                  <Typography fontWeight="600" fontSize="0.8rem">
                    {cell.getValue()}
                  </Typography>
                  {item?.icon_pos === 'right' &&
                    (styling[icon] === 'up' ? (
                      <ArrowDropUpOutlinedIcon />
                    ) : styling[icon] === 'down' ? (
                      <ArrowDropDownIcon />
                    ) : null)}
                </Stack>
              </div>
            );
          }
        }
      ]
    }));
  }, []);

  // const CustomCellRenderer = () => {
  //   // const { ref, activate, deactivate } = useTooltip();

  //   return <div>lol de pol</div>;
  // };
  // const shouldRemoveBorder = (cellValue) => {
  //   // Check if the cell value is empty
  //   return cellValue === '';
  // };

  const shouldHaveBackgroundColor = (cell) => {
    const bgColor = cell?.column?.columnDef?.bgColor; //column data
    const styling = cell?.row?.original; //row data
    return styling[bgColor];
  };

  const table = useMaterialReactTable({
    columns,
    data: chart_data || [],
    enableExpandAll: false, // Hide expand all double arrow in column header
    enableExpanding: false,
    filterFromLeafRows: true, // Apply filtering to all rows instead of just parent rows
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    getSubRows: (row) => row.subRows || [], // Ensure all rows have sub-rows
    initialState: { expanded: true, density: 'compact' }, // Expand all rows by default
    paginateExpandedRows: false, // When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    enableGlobalFilter: false, // Hide global filter bar
    enableTopToolbar: false,
    enablePagination: false,
    muiTableContainerProps: { sx: { maxHeight: '600px' } },

    // enableColumnResizing: true,
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        // borderBottom: shouldRemoveBorder(cell.getValue()) ? 'none' : undefined,
        backgroundColor: shouldHaveBackgroundColor(cell) ? '#F9D23C' : '#FFFFFF',
        fontWeight: 'bold',
        whiteSpace: 'noraml',
        wordBreak: 'break-word'
      }
    }),
    muiTableHeadCellProps: shouldHaveColumnStyle
  });

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ color: content?.tbl_nm_colr }}>
        {content?.tbl_nm || ''}
      </Typography>
      <MaterialReactTable table={{ ...table }} />
    </>
  );
};

export default MrtTableWithSubColumn;

MrtTableWithSubColumn.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  cell: PropTypes.shape({
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    getValue: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired
    // Add other expected properties with appropriate types
  }).isRequired
};
