import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

const AgGridTestTable = () => {
  // Hardcoded row data for testing
  const [rowData] = useState([
    {
      make: 'Tesla',
      model: 'Model S',
      price: 104990,
      electric: true,
      year: 2023,
      color: 'Midnight Silver'
    },
    {
      make: 'Tesla',
      model: 'Model X',
      price: 114990,
      electric: true,
      year: 2024,
      color: 'Pearl White'
    },
    {
      make: 'Tesla',
      model: 'Model 3',
      price: 56990,
      electric: true,
      year: 2022,
      color: 'Deep Blue'
    },
    { make: 'Tesla', model: 'Model Y', price: 64950, electric: true, year: 2023, color: 'Red' },
    { make: 'Ford', model: 'F-150', price: 33850, electric: false, year: 2024, color: 'Blue' },
    {
      make: 'Ford',
      model: 'Mustang Mach-E',
      price: 46990,
      electric: true,
      year: 2023,
      color: 'Gray'
    },
    { make: 'Toyota', model: 'Corolla', price: 29600, electric: false, year: 2022, color: 'White' },
    { make: 'Toyota', model: 'Camry', price: 35250, electric: false, year: 2023, color: 'Silver' },
    { make: 'Honda', model: 'Civic', price: 28600, electric: false, year: 2024, color: 'Black' },
    { make: 'Honda', model: 'CR-V', price: 32600, electric: false, year: 2023, color: 'Red' },
    {
      make: 'Chevrolet',
      model: 'Bolt EV',
      price: 26600,
      electric: true,
      year: 2022,
      color: 'Blue'
    },
    {
      make: 'Chevrolet',
      model: 'Tahoe',
      price: 56600,
      electric: false,
      year: 2023,
      color: 'White'
    },
    { make: 'Nissan', model: 'Leaf', price: 27800, electric: true, year: 2024, color: 'Silver' },
    { make: 'Nissan', model: 'Rogue', price: 33800, electric: false, year: 2023, color: 'Black' },
    { make: 'Hyundai', model: 'Ioniq 5', price: 44900, electric: true, year: 2022, color: 'Blue' },
    { make: 'Hyundai', model: 'Santa Fe', price: 34600, electric: false, year: 2024, color: 'Red' }
  ]);

  // Column definitions with rowSpan and custom cell renderer for 'make'
  const [colDefs] = useState([
    { field: 'make', pinned: 'left' },
    { field: 'model', pinned: 'left' },
    { field: 'year' },
    { field: 'price' },
    { field: 'color' },
    { field: 'electric' }
  ]);

  const getRowStyle = (params) => {
    return { background: params.node.rowIndex % 2 === 0 ? 'white' : 'whitesmoke' };
  };
  return (
    // Wrapping container with theme & size
    <div
      className="ag-theme-quartz"
      style={{
        height: 500,
        width: '100%',
        '--ag-header-background-color': 'lightgreen' // AG Grid CSS variable for header background
      }} // The Data Grid will fill the size of the parent container
    >
      <AgGridReact rowData={rowData} columnDefs={colDefs} getRowStyle={getRowStyle} />
    </div>
  );
};

export default AgGridTestTable;
