import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { IoTriangle } from 'react-icons/io5';
import { TbTriangleInvertedFilled } from 'react-icons/tb';

// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import { IoTriangle } from 'react-icons/io5';

// import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0)
// ];
// const tableRowName = ['name', 'calories', 'fat', 'carbs', 'protein'];
export default function AlignedTables({ layout, content, apidata }) {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ height: '150px', backgroundColor: 'transparent' }}
    >
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {layout?.pageContent?.[0]?.tableFieldData?.map((item) => (
              <>
                <TableCell align="start" sx={{ fontWeight: 'bold' }}>
                  {item?.fld_l}
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {chart_data?.map((item) => (
            <>
              <TableRow
                key={item.party_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {layout?.pageContent?.[0]?.tableData.map((child) => (
                  <>
                    <TableCell
                      align="start"
                      sx={{ fontWeight: 'bold', color: item?.[child?.text_color] }}
                    >
                      {child?.icon_pos === 'left' &&
                        ((item?.[child?.icon] === 'up' && <IoTriangle />) ||
                          (item?.[child?.icon] === 'down' && <TbTriangleInvertedFilled />))}
                      {item?.[child?.fld_id]}
                      {child?.icon_pos === 'right' &&
                        ((item?.[child?.icon] === 'up' && <IoTriangle />) ||
                          (item?.[child?.icon] === 'down' && <TbTriangleInvertedFilled />))}
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AlignedTables.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
