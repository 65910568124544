import React, { useEffect, useState, useCallback, useRef } from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GroupOfDropdownNullPassing } from '../../../features/counter/counterSlice';
import { GroupOfDropdownValuePassing } from '../../../features/counter/counterSlice';
import SingleDropDowns from './SingleDropDowns';

const SingleParent = ({ content, apidata }) => {
  const [copyData, setCopyData] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [keytobefilter, setKeyToBeFilter] = useState('');
  const [valuetofilter, setValueToFilter] = useState('');
  const [flag, setFlage] = useState(false);
  const dispatch = useDispatch();
  const prevFieldValuesRef = useRef();

  // get all keys and makes their value null in an object
  useEffect(() => {
    if (content?.drp_dn_grp_fld_data.length) {
      let allkeys = content?.drp_dn_grp_fld_data.map((key) => key.fld);
      const objectWithNullValues = Object.fromEntries(allkeys.map((key) => [key, null]));
      dispatch(GroupOfDropdownNullPassing(objectWithNullValues));
    }
  }, [content?.drp_dn_grp_fld_data.length]);

  useEffect(() => {
    if (keytobefilter === '') {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData(chart_data);
    } else if (keytobefilter === 'role' && valuetofilter !== '') {
      //copydata and filtering based on what user has selected in dropdown
      const chart_data = apidata?.drp_dn_grp_data;
      const FilteredDropDownValues = chart_data.filter(
        (data) => data[keytobefilter] === valuetofilter
      );
      setCopyData(FilteredDropDownValues);
    }
  }, [keytobefilter, apidata, valuetofilter]);

  useEffect(() => {
    if (keytobefilter !== '' && valuetofilter !== '') {
      const modifiedObjects = { ...fieldValues, [keytobefilter]: [valuetofilter] };
      if (JSON.stringify(modifiedObjects) !== JSON.stringify(prevFieldValuesRef.current)) {
        dispatch(GroupOfDropdownValuePassing(modifiedObjects));
      }
      prevFieldValuesRef.current = modifiedObjects;
    }
  }, [keytobefilter, valuetofilter, fieldValues]);

  useEffect(() => {
    if (flag) {
      if (JSON.stringify(fieldValues) !== JSON.stringify(prevFieldValuesRef.current)) {
        console.log('clickedapi...', flag, fieldValues);
        dispatch(GroupOfDropdownValuePassing(fieldValues));
      }
    }
  }, [flag, keytobefilter, fieldValues]);

  const updateFieldValues = useCallback(
    (fieldName, values) => {
      setFieldValues((prev) => {
        return {
          ...prev,
          [fieldName]: values
        };
      });
    },
    [copyData]
  );
  console.log('somedata...', keytobefilter, valuetofilter, fieldValues);
  return (
    <div style={{ backgroundColor: '#f5f8f9', padding: '10px' }}>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        {content?.drp_dn_grp_fld_data.map((item) => (
          <>
            {item.is_multi_sel === 0 ? (
              <SingleDropDowns
                fld_l={item?.fld}
                DefaultValue={item?.def_val}
                OptionsValue={item?.fld_values}
                apidata={copyData}
                updateFieldValues={updateFieldValues}
                fieldValues={fieldValues}
                setKeyToBeFilter={setKeyToBeFilter}
                keytobefilter={keytobefilter}
                setValueToFilter={setValueToFilter}
                setFlage={setFlage}
                item={item}
              />
            ) : (
              ''
            )}
          </>
        ))}
      </Stack>
    </div>
  );
};

export default SingleParent;
SingleParent.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
